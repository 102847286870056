@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2/Exo2-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2/Exo2-Regular.ttf');
  font-style: 400;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2/Exo2-Bold.ttf');
  font-style: 700;
}
